<script context="module">
  import navigatorLanguages from 'navigator-languages'
  import { setLocale } from '/@/multilang'

  setLocale(navigatorLanguages())
</script>

<script>
  import * as Sentry from '@sentry/svelte'
  import { setContext } from 'svelte'
  import { writable } from 'svelte/store'
  import UAParser from 'ua-parser-js'
  import Lazy from './Lazy.svelte'
  import UnhandledErrorApp from './UnhandledErrorApp.svelte'
  import { settings, user } from './store.js'
  import LocalStore from '/@/components/MobileWall/utils/store/LocalStore.js'
  import { Route, router } from '/@/router.js'

  export let prefix
  export let component
  export let config = '{}'
  export let context = undefined

  router.set_url_prefix(prefix)
  Object.assign(settings, {
    ...JSON.parse(config),
    context,
  })

  // eslint-disable-next-line no-undef
  if (import.meta.env.MODE === 'production') {
    // Catch uncaught exceptions
    // eslint-disable-next-line no-inner-declarations, no-unused-vars
    function onError(err) {
      // err.preventDefault?.()
      // redirect to /{prefix}/unhanlded_error ({prefix}/Content.svelte)
      console.error(err)

      if (
        err?.message ==
        'ResizeObserver loop completed with undelivered notifications.'
      )
        return

      window.location = router.url('/unhandled_error')
    }

    const url = new URL(settings.sentryApi)
    Sentry.init({
      dsn: `${window.location.protocol}//${url.username}@${window.location.host}/sentry${url.pathname}`,
      release: import.meta.env.GIT_REV,
      integrations: [new Sentry.BrowserTracing()],
      autoSessionTracking: false,
    })

    Sentry.configureScope((scope) => {
      scope.setTag('component', 'frontend')
      scope.setExtra('client', UAParser())
      scope.setExtra('user', {
        uuid: $user?.uuid,
        model: $user?.model,
        username: $user?.username,
        is_superuser: $user?.is_superuser,
        permissions: $user?.permissions,
      })
    })

    window.addEventListener('error', onError)
    window.addEventListener('unhandledrejection', onError)
  }

  // force reload when there is a new app version available
  $: router.handleReload($router)

  // Initialize some global stores. They are here because they are shared
  // between MobileWall components, as well as some dashboard panels.

  function initStore_setContext(key, localStorage = false) {
    if (!key) throw new Error('key is required')
    const store = localStorage
      ? LocalStore({ key, value: null })
      : new writable(null)
    setContext(key, store)
    return store
  }

  initStore_setContext('editorState', true)
  initStore_setContext('selectedItem', true)
  initStore_setContext('selectedFloorplanID', true)
  initStore_setContext('editorToolPosition')
  initStore_setContext('editorToolPositionRaw')
  initStore_setContext('editorToolRotation')
  initStore_setContext('hoveredItem')
</script>

{#if location.pathname === `${prefix}/unhandled_error`}
  <UnhandledErrorApp />
{:else}
  <Route path="{prefix}/*">
    {#if component == 'staff'}
      <Lazy component="{import('./staff/App.svelte')}" />
    {:else}
      <UnhandledErrorApp />
    {/if}
  </Route>
{/if}
